import * as Category from '@common/models/CategoryShowCase';
import * as HeroBanner from '@common/models/HeroBanner';
import * as HtmlBlock from '@common/models/HtmlBlock';
import * as ImageAndText from '@common/models/ImageAndText';
import * as PageHeaderBuilder from '@common/models/PageHeaderBuilder';
import * as ProductShowCase from '@common/models/ProductShowCase';
import * as RichTextEditor from '@common/models/RichTextEditor';
import { StorePageNameTypes } from '@common/models/StorePage';
import * as Testimonials from '@common/models/Testimonial';
import * as Text from '@common/models/Text';
import debounce from 'lodash/debounce';

import { ADD_NEW_SECTION_ID } from './constants';

export const PREVIEW_RTE = 'PREVIEW_RTE';
export const PREVIEW_HERO_BANNER = 'PREVIEW_HERO_BANNER';
export const SCROLL_TO_SECTION = 'SCROLL_TO_SECTION';
export const PREVIEW_TEXT = 'PREVIEW_TEXT';
export const PREVIEW_RICH_TEXT_EDITOR = 'PREVIEW_RICH_TEXT_EDITOR';
export const PREVIEW_IMAGE_TEXT = 'PREVIEW_IMAGE_TEXT';
export const PREVIEW_HTML_BLOCK = 'PREVIEW_HTML_BLOCK';
export const PREVIEW_PAGE_HEADER = 'PREVIEW_PAGE_HEADER';
export const PREVIEW_PRODUCT_SHOWCASE = 'PREVIEW_PRODUCT_SHOWCASE';
export const SCROLL_TO_BOTTOM = 'SCROLL_TO_BOTTOM';
export const ADD_PREVIEW = 'ADD_PREVIEW';
export const RELOAD_AFTER_CREATION = 'RELOAD_AFTER_CREATION';
export const PREVIEW_CATEGORY_LIST = 'PREVIEW_CATEGORY_LIST';
export const GET_USER_SECTION = 'GET_USER_SECTION';
export const RELOAD_SECTION_PREVIEW = 'RELOAD_SECTION_PREVIEW';
export const DELETE_PREVIEW = 'DELETE_PREVIEW';
export const PREVIEW_STORE_UPDATES = 'PREVIEW_STORE_UPDATES';
export const PREVIEW_STORE_TESTIMONIALS = 'PREVIEW_STORE_TESTIMONIALS';
export const PREVIEW_IS_DESKTOP_VIEW = 'PREVIEW_IS_DESKTOP_VIEW';
export const PREVIEW_THEME_CUSTOMISATION = 'PREVIEW_THEME_CUSTOMISATION';
export const PREVIEW_CUSTOM_PAGE = 'PREVIEW_CUSTOM_PAGE';
export const PREVIEW_VIEW = 'PREVIEW_VIEW';
export const REDIRECT_TO_CUSTOM_PAGE = 'REDIRECT_TO_CUSTOM_PAGE';
export const REDIRECT_TO_CUSTOM_PAGE_AFTER_DELETING_PAGE = 'REDIRECT_TO_CUSTOM_PAGE_AFTER_DELETING_PAGE';
export const RELOAD_IFRAME_AFTER_CUSTOM_PAGE_EDIT = 'RELOAD_IFRAME_AFTER_CUSTOM_PAGE_EDIT';

interface addPreviewType {
  type: string;
  layoutId: number;
  name: string;
}

interface redirectToCustomPageType {
  customPageName: string;
  customPageId: string;
  isLoginRequired: boolean;
}

const send = (type: string, previewData?: Record<string, unknown>) => {
  document.getElementById('storefront').contentWindow?.postMessage(
    {
      type,
      previewData,
    },
    '*',
  );
};

const sendWithDelay = debounce(send, 200);

export const sendMessage = (
  type: string,
  previewData?: Record<string, unknown>,
  debounceEnabled = false,
  isReload = false,
): void => {
  if (debounceEnabled) {
    sendWithDelay(type, previewData);
  } else if (isReload) {
    const storefrontIframe = document.getElementById('storefront');
    storefrontIframe.src = storefrontIframe.src;
  } else {
    send(type, previewData);
  }
};

export const previewText = (data: Record<string, unknown>): void => {
  const previewData = Text.processResponse(data);
  sendMessage(PREVIEW_TEXT, previewData, true);
};

export const previewRichTextEditor = (data: Record<string, unknown>): void => {
  const previewData = RichTextEditor.processResponse(data);
  sendMessage(PREVIEW_RICH_TEXT_EDITOR, previewData, true);
};

export const previewImageAndText = (data: Record<string, unknown>): void => {
  const previewData = ImageAndText.processResponse(data);
  sendMessage(PREVIEW_IMAGE_TEXT, previewData, true);
};

export const previewHtmlBlock = (data: Record<string, unknown>): void => {
  const previewData = HtmlBlock.processResponse(data);
  sendMessage(PREVIEW_HTML_BLOCK, previewData, true);
};

export const previewPageHeader = (data: Record<string, unknown>): void => {
  const previewData = PageHeaderBuilder.previewProcessResponse(data);
  sendMessage(PREVIEW_PAGE_HEADER, previewData, true);
};

export const previewHeroBanner = (data: Record<string, unknown>): void => {
  const previewData = HeroBanner.previewProcessResponse(data);
  sendMessage(PREVIEW_HERO_BANNER, previewData, true);
};

export const previewProductShowCase = (data: Record<string, unknown>): void => {
  const previewData = ProductShowCase.previewProcessResponse(data);
  sendMessage(PREVIEW_PRODUCT_SHOWCASE, previewData, true);
};

export const previewCategoryList = (data: Record<string, unknown>): void => {
  const previewData = Category.previewProcessResponse(data);
  sendMessage(PREVIEW_CATEGORY_LIST, previewData, true);
};

export const previewStoreUpdates = (data: Record<string, unknown>): void => {
  const previewData = data;
  sendMessage(PREVIEW_STORE_UPDATES, previewData, true);
};

export const previewStoreTestimonials = (data: Record<string, unknown>): void => {
  const previewData = Testimonials.previewProcessResponse(data);
  sendMessage(PREVIEW_STORE_TESTIMONIALS, previewData, true);
};

export const addPreview = ({ type, layoutId, name, customPageId }: addPreviewType): void => {
  const previewData = {
    id: ADD_NEW_SECTION_ID,
    sectionId: '',
    order: 9999,
    sectionType: type,
    layoutId: layoutId,
    layoutName: name,
    isDefault: false,
    isVisible: true,
    customPageId,
  };
  sendMessage(ADD_PREVIEW, previewData);
};

export const scrollToSection = (sectionId: string): void => {
  sendMessage(SCROLL_TO_SECTION, { id: sectionId });
};

export const scrollToBottom = (): void => {
  sendMessage(SCROLL_TO_BOTTOM);
};

export const getUserSectionPreview = (currentSelectedPage: StorePageNameTypes): void => {
  const previewData = currentSelectedPage;
  sendMessage(GET_USER_SECTION, previewData);
};

export const reloadSectionPreview = (id: string, sectionType: string): void => {
  const previewData = { id, sectionType };
  sendMessage(RELOAD_SECTION_PREVIEW, previewData);
};

export const deleteSectionPreview = (): void => {
  sendMessage(DELETE_PREVIEW);
};

export const previewIsDesktopView = (): void => {
  sendMessage(PREVIEW_IS_DESKTOP_VIEW);
};

export const previewThemeCustomisation = (): void => {
  sendMessage(PREVIEW_THEME_CUSTOMISATION, {}, true);
};

export const previewCustomPage = (): void => {
  sendMessage(PREVIEW_CUSTOM_PAGE, {}, true);
};

export const previewView = (): void => {
  sendMessage(PREVIEW_VIEW);
};

export const previewThemeSelection = (): void => {
  sendMessage('', {}, false, true);
};

export const redirectToCustomPage = ({
  customPageName,
  customPageId,
  isLoginRequired,
}: redirectToCustomPageType): void => {
  const previewData = { customPageName, customPageId, isLoginRequired };
  sendMessage(REDIRECT_TO_CUSTOM_PAGE, previewData);
};

export const redirectToCustomPageAfterDeletingPage = (deletedUrlPath: string): void => {
  const previewData = deletedUrlPath;
  sendMessage(REDIRECT_TO_CUSTOM_PAGE_AFTER_DELETING_PAGE, previewData);
};

export const reloadIframeAfterCustomPageEdit = (): void => {
  sendMessage(RELOAD_IFRAME_AFTER_CUSTOM_PAGE_EDIT, {});
};
