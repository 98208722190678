import { editCategoryList, fetchCategoryListRequest } from '@common/store/categoryList/slice';
import {
  addCustomPageUserSections,
  fetchCustomPageUserSectionRequest,
} from '@common/store/customPageUserSections/slice';
import { editHeroBanner, fetchSliderRequest } from '@common/store/heroBanner/slice';
import { editHtmlBlock, fetchHtmlBlockRequest } from '@common/store/htmlBlock/slice';
import { editImageAndText, fetchImageAndTextRequest } from '@common/store/imageAndText/slice';
import { editPageHeader, fetchPageHeaderRequest } from '@common/store/pageHeader/slice';
import { editProductShowCaseList, fetchProductListRequest } from '@common/store/productList/slice';
import { editRichTextEditor, fetchRichTextEditorRequest } from '@common/store/richTextEditor/slice';
import { editStoreTestimonials, fetchStoreTestimonialsRequest } from '@common/store/storeTestimonials/slice';
import { editText, fetchTextRequest } from '@common/store/text/slice';
import { fetchThemeCustomisationRequest } from '@common/store/themeCustomisation/slice';
import {
  addSections,
  deleteSection,
  fetchUserSectionsRequest,
  UserSectionsSliceType,
} from '@common/store/userSections/slice';
import { getIsHomePageURL } from '@common/utils';
import { ADD_NEW_SECTION_ID, windowScrollToTop } from '@common/utils/constants';
import history from '@common/utils/history';
import {
  ADD_PREVIEW,
  DELETE_PREVIEW,
  GET_USER_SECTION,
  PREVIEW_CATEGORY_LIST,
  PREVIEW_CUSTOM_PAGE,
  PREVIEW_HERO_BANNER,
  PREVIEW_HTML_BLOCK,
  PREVIEW_IMAGE_TEXT,
  PREVIEW_IS_DESKTOP_VIEW,
  PREVIEW_PAGE_HEADER,
  PREVIEW_PRODUCT_SHOWCASE,
  PREVIEW_RICH_TEXT_EDITOR,
  PREVIEW_STORE_TESTIMONIALS,
  PREVIEW_TEXT,
  PREVIEW_THEME_CUSTOMISATION,
  PREVIEW_VIEW,
  REDIRECT_TO_CUSTOM_PAGE,
  REDIRECT_TO_CUSTOM_PAGE_AFTER_DELETING_PAGE,
  RELOAD_IFRAME_AFTER_CUSTOM_PAGE_EDIT,
  RELOAD_SECTION_PREVIEW,
  SCROLL_TO_BOTTOM,
  SCROLL_TO_SECTION,
} from '@common/utils/postmessages';
import InnerHTML from 'dangerously-set-html-content';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../store';

interface PostMessageProps {
  userSections: UserSectionsSliceType;
  fetchUserSectionsRequest: typeof fetchUserSectionsRequest;
  editText: typeof editText;
  editHeroBanner: typeof editHeroBanner;
  addSections: typeof addSections;
  editRichTextEditor: typeof editRichTextEditor;
  editImageAndText: typeof editImageAndText;
  editHtmlBlock: typeof editHtmlBlock;
  editPageHeader: typeof editPageHeader;
  editProductShowCaseList: typeof editProductShowCaseList;
  fetchSliderRequest: typeof fetchSliderRequest;
  fetchPageHeaderRequest: typeof fetchPageHeaderRequest;
  fetchHtmlBlockRequest: typeof fetchHtmlBlockRequest;
  fetchImageAndTextRequest: typeof fetchImageAndTextRequest;
  fetchRichTextEditorRequest: typeof fetchRichTextEditorRequest;
  fetchTextRequest: typeof fetchTextRequest;
  fetchProductListRequest: typeof fetchProductListRequest;
  fetchCategoryListRequest: typeof fetchCategoryListRequest;
  deleteSection: typeof deleteSection;
  editCategoryList: typeof editCategoryList;
  fetchStoreTestimonialsRequest: typeof fetchStoreTestimonialsRequest;
  editStoreTestimonials: typeof editStoreTestimonials;
  fetchThemeCustomisationRequest: typeof fetchThemeCustomisationRequest;
  fetchCustomPageUserSectionRequest: typeof fetchCustomPageUserSectionRequest;
  addCustomPageUserSections: typeof addCustomPageUserSections;
}
class PostMessage extends React.Component<PostMessageProps> {
  constructor(props) {
    super(props);
    this.state = { highlighted: '' };
  }
  componentDidMount() {
    const {
      editText,
      editRichTextEditor,
      editImageAndText,
      editHtmlBlock,
      editPageHeader,
      editHeroBanner,
      addSections,
      fetchUserSectionsRequest,
      fetchSliderRequest,
      fetchPageHeaderRequest,
      fetchHtmlBlockRequest,
      fetchImageAndTextRequest,
      fetchRichTextEditorRequest,
      fetchTextRequest,
      fetchProductListRequest,
      fetchCategoryListRequest,
      deleteSection,
      editProductShowCaseList,
      editCategoryList,
      fetchStoreTestimonialsRequest,
      editStoreTestimonials,
      fetchThemeCustomisationRequest,
      fetchCustomPageUserSectionRequest,
      addCustomPageUserSections,
    } = this.props;

    const setHighlighted = (id) => {
      if (id) {
        this.setState({ ...this.state, highlighted: id });
      }
    };

    window.addEventListener('message', (event) => {
      const { data } = event;
      const { previewData } = data;
      const pageHeaderSection = this.props?.userSections?.userSections.filter(
        (items) => items?.sectionType === 'Page Header',
      )[0];

      const {
        id,
        sectionType,
        list,
        layout,
        title,
        banners,
        testimonials,
        showcaseType,
        productDetails,
        customPageId,
        customPageName,
        isLoginRequired,
      } = previewData || {};

      switch (data.type) {
        case PREVIEW_TEXT:
          editText({
            id: id,
            data: previewData,
            layout: layout,
          });
          return;
        case PREVIEW_RICH_TEXT_EDITOR:
          editRichTextEditor({
            id: id,
            result: previewData,
          });
          return;
        case PREVIEW_IMAGE_TEXT:
          editImageAndText({
            id: id,
            result: previewData,
            layout: layout,
          });
          return;
        case PREVIEW_HTML_BLOCK:
          editHtmlBlock({
            id: id,
            result: previewData,
          });
          return;
        case PREVIEW_PAGE_HEADER:
          editPageHeader({
            id: id,
            result: previewData,
            layout: layout,
          });
          return;

        case PREVIEW_CATEGORY_LIST:
          editCategoryList({
            id: id,
            categories: list,
            title: title,
            layout: layout,
          });
          return;

        case SCROLL_TO_SECTION:
          const scrollTop =
            document.getElementById(`${id}-scroll`)?.getBoundingClientRect().top + window?.scrollY - 200;
          windowScrollToTop(scrollTop);
          setHighlighted(id);
          return;

        case PREVIEW_HERO_BANNER:
          editHeroBanner({
            id: id,
            data: banners,
            layout: layout,
          });
          return;

        case PREVIEW_PRODUCT_SHOWCASE:
          editProductShowCaseList({
            id: id,
            list: list,
            layout: layout,
            title: title,
            showcaseType: Number(showcaseType),
            productDetails: productDetails,
          });
          return;

        case PREVIEW_STORE_TESTIMONIALS:
          editStoreTestimonials({
            id: id,
            result: {
              testimonials,
              layout: layout,
              title: title,
            },
          });
          return;

        case SCROLL_TO_BOTTOM:
          const scrollBottom =
            document.getElementById(`${ADD_NEW_SECTION_ID}-scroll`).getBoundingClientRect().top + window.scrollY - 400;
          windowScrollToTop(scrollBottom);
          setHighlighted(ADD_NEW_SECTION_ID);
          return;

        case ADD_PREVIEW:
          previewData.customPageId === 'home'
            ? addSections({
                ...previewData,
              })
            : addCustomPageUserSections({
                ...previewData,
              });

          return;

        case GET_USER_SECTION:
          getIsHomePageURL(previewData.value)
            ? fetchUserSectionsRequest()
            : fetchCustomPageUserSectionRequest(`custom-page/${customPageId}`);
          return;

        case RELOAD_SECTION_PREVIEW:
          switch (sectionType) {
            case 'HERO_BANNER':
              fetchSliderRequest(id);
              return;
            case 'PAGE_HEADER':
              fetchPageHeaderRequest(id);
              return;
            case 'TEXT':
              fetchTextRequest(id);
              return;
            case 'RICH_TEXT_EDITOR':
              fetchRichTextEditorRequest(id);
              return;
            case 'IMAGE_TEXT':
              fetchImageAndTextRequest(id);
              return;
            case 'HTML_BLOCK':
              fetchHtmlBlockRequest(id);
              return;
            case 'PRODUCT_SHOWCASE':
              fetchProductListRequest({ sectionId: id });
              return;
            case 'CATEGORY_SHOWCASE':
              fetchCategoryListRequest({ sectionId: id });
              return;
            case 'TESTIMONIALS':
              fetchStoreTestimonialsRequest(id);
              return;
          }
          return;

        case DELETE_PREVIEW:
          deleteSection();
          return;

        case PREVIEW_IS_DESKTOP_VIEW:
          this.props.handleToRender();
          return;

        case PREVIEW_THEME_CUSTOMISATION:
          fetchThemeCustomisationRequest();
          return;

        case PREVIEW_CUSTOM_PAGE:
          if (pageHeaderSection) {
            fetchPageHeaderRequest(pageHeaderSection.id);
          }
          return;

        case PREVIEW_VIEW:
          this.setState({ ...this.state, highlighted: '' });
          return;

        case REDIRECT_TO_CUSTOM_PAGE:
          getIsHomePageURL(customPageName)
            ? fetchUserSectionsRequest()
            : fetchCustomPageUserSectionRequest(`custom-page/${customPageId}`);
          history.push(
            isLoginRequired
              ? '/account/login?redirect_url=%2F'
              : getIsHomePageURL(customPageName)
              ? ''
              : customPageName,
          );
          return;

        case REDIRECT_TO_CUSTOM_PAGE_AFTER_DELETING_PAGE:
          if (event?.currentTarget?.location?.pathname === previewData) {
            history.push('');
          }
          return;

        case RELOAD_IFRAME_AFTER_CUSTOM_PAGE_EDIT:
          window.location.reload();
          return;
      }
    });
  }
  render() {
    const { highlighted } = this.state;
    const stylesString = `div[id="${highlighted}"] {border: 2px solid #479AE6;}`;
    return (
      highlighted && (
        <>
          <InnerHTML html={`<style>${stylesString || ''}  </style>`} />
        </>
      )
    );
  }
}

export default connect(
  ({ userSections }: RootState) => ({
    userSections,
  }),
  {
    editText,
    editRichTextEditor,
    editImageAndText,
    editHtmlBlock,
    editPageHeader,
    fetchUserSectionsRequest,
    fetchCustomPageUserSectionRequest,
    editHeroBanner,
    editProductShowCaseList,
    addSections,
    fetchSliderRequest,
    fetchPageHeaderRequest,
    fetchTextRequest,
    fetchRichTextEditorRequest,
    fetchImageAndTextRequest,
    fetchHtmlBlockRequest,
    fetchProductListRequest,
    fetchCategoryListRequest,
    deleteSection,
    editCategoryList,
    editStoreTestimonials,
    fetchStoreTestimonialsRequest,
    fetchThemeCustomisationRequest,
    addCustomPageUserSections,
  },
)(PostMessage);
